/* eslint no-useless-escape: 0 */
import $ from 'jquery'
import { mapState } from 'vuex'

export default {
  name: 'CheckoutItemDetails',
  data () {
    return {
      itemIndex: 0
    }
  },
  props: [
    'moreInfo'
  ],
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange
    }),
    prismAdded: function () {
      return this.filterPrisms(this.moreInfo, /prism_/).some(val => val > 0)
    }
  },
  methods: {
    formatPrice (value) {
      return (value / 1).toFixed(2)
    },
    filterPrisms (obj, filter) {
      let keys = []
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && filter.test(key)) {
          keys.push(+obj[key])
        }
      }
      return keys
    },
    removeTags: function (string) {
      const htmlTagRe = /<\/?[\w\s="/.':;#-\/]+>/gi
      return string.replace(htmlTagRe, ' ')
    },
    decodeHtml: function (html) {
      let txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    }
  },
  mounted () {
    $('#detailsModal').on('show.bs.modal', function (event) {
      let button = $(event.relatedTarget)
      this.itemIndex = button.data('item-index')

      // let modal = $(this)
      // modal.find('.modal-title').text('Modal for item ' + this.itemIndex)
    })
  }
}
